#resources-page{
    padding: 40px 100px;
    .resources-sermons{
        margin-top: 0px !important;
        padding: 0px !important;
        background-color: white !important;
    }
}

@media screen and (max-width: 426px){
    #resources-page{
        padding: 20px;
        
    }
}