#sidebar{
    width: 20%;
    background-color: rgb(0, 23, 85);
    height: 100vh;
    position: fixed;
    overflow-y: auto;
    z-index: 99;
    .close-icon{
        color: white;
        position: absolute;
        right: 10px;
        top: 10px;
        display: none;
    }
    .sidebar-title{
        // padding: 52px 10px;
        font-weight: 800;
        font-size: 25px;
        text-align: center;
        color: #030166;
        border-right:1px solid #e0e0e0;
        display: flex;
        justify-content: center;
        align-items: center;
        display: flex;
        height: 3.75rem;
        background-color: #fff;
        padding-left: 1.75rem;
        padding-right: 1.75rem;
        padding-bottom: 0.5rem;
        padding-top: 0.5rem;
        
    }
    .navigations{
        padding: 15px;
        display: flex;
        flex-direction: column;
        margin-top: 1rem!important;
        .navigation-item{
            .navigation-link{
                margin-bottom: 0.375rem;
                margin-top: 0.375rem;
                padding: 0.375rem 1.75rem;
                // border-radius: 10px;
                color: white;
                font-size: .875rem;
                display: flex;
                gap: 10px;
                align-items: center;
                transition: .4s ease-in-out;
                min-height: 36px;
                padding-left: 22px;
                padding-right: 22px;
                position: relative;
                svg{
                    font-size: 1.125rem;
                    line-height: 1.4;
                }
                &:hover{
                    background: #eff6ff1a;
                    color: #fff;
                    cursor: pointer;
                    border-radius: 5px;
                }
            }
            .active{
                // background-color: white;
                // color: #030166;
                font-weight: 600;
                border-left: 4px solid #1c91f1;
                color: #1c91f1;
                &::before{
                    background: #eff6ff1a;
                    border-radius: 5px;
                    content: "";
                    inset: 0 10px;
                    position: absolute;
                }
                svg{
                    color:#1c91f1;
                }
              }
        }
    }
}

// MEDIA QUERIES
@media screen and (max-width: 1025px){
    #sidebar{
        .sidebar-title{
            font-size: 20px;
        }
        .navigations{
            .navigation-item{
                .navigation-link{
                    font-size: 14px;
                    svg{
                        font-size: 20px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 769px){
    #sidebar{
        width: 30%;
        transition: 1s ease-in-out;
        display: none;
        .close-icon{
            display: block;
        }
    }
}

@media screen and (max-width: 426px){
    #sidebar{
        width: 50%;
    }
}