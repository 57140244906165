#carousel{
    width: 100%;
    height: 121vh;
    display: flex;
    position: relative;
    overflow: hidden;
    
    svg{
        font-size: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        cursor: pointer;
        color: white;
        z-index: 2;
        color: #030166;
    }
    .wrapper{
        height: 100%;
        display: flex;
        transition: all 1s ease;
        .slide{
            width: 100vw;
            display: flex;
            align-items: center;
            // background-image: url("../../assets/carousel/image2.png"), linear-gradient(to right,rgba(32, 26, 1,.521) 45%,rgba(255, 255, 255, 0.212) 100%);
            background-repeat: no-repeat;
            background-position: center;
            // background: linear-gradient(to right, rgba(32, 26, 1,1) 0%, rgba(255, 255, 255, 0.212) 100%);
            background-blend-mode: overlay;
            // -webkit-background-size: cover;
            // -moz-background-size: cover;
            // -o-background-size: cover;
            background-size: cover;
            .carousel-text{
                color: white;
                width: 80%;
                margin: auto;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                h3{
                    font-size: 39.81px;
                    font-weight: 500;
                    
                    color: #000;
                }
                h1{
                    font-size: 60px;
                    font-weight: 700;
                    color: #030166;
                    width: 70%;
                }
                h4{
                    font-size: 31px;
                    color: #000;
                    font-weight: 800;
                    line-height: 25px;
                }
                
            }
        }
    }
}
