#loading-page{
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
    justify-content: center;
    height: 100vh;
    
    img{
        height: 100px;
    }
}