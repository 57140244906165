#attendance{
    padding: 54px 27px;
    min-height:86vh;
    .attendance-top{
        display: flex;
        justify-content: space-between;
        .attendance-title{
            padding: 12px 44px;
            background-color: white;
            color: #000000;
        }
    }
    .filter-box{
        margin-top: 20px;
        width: 20%;
        margin: 20px auto;
        input[type=date]{
            padding: 10px 20px;
            border: 1px solid #eee;
            background-color: white;
        }
        input[type=submit]{
            padding: 11px 20px;
            background-color: var(--main-color);
            color: white;
            border: none;
            font-size: 16px;
            font-weight: 600;
            cursor: pointer;
        }
    }
}

.tabs{
    display: flex;
    gap: 3px;
    border-bottom: 1px solid #0000001A;
    margin-bottom: 20px;
    .tab{
        padding: 12px 44px;
        &:hover{
            cursor: pointer;
            border-bottom: 1px solid var(--main-color);
            background-color: white;    
        }
    }
    .tab-active{
        border-bottom: 1px solid var(--main-color);
        background-color: white;
    }
}

@media screen and (max-width: 426px) {
    .tabs{
        overflow: scroll;
        .tab{
            padding: 10px 20px;
        }
    }
}