.modal-overlay{
    position: fixed !important;
    height: 100vh;
    width: 100%;
    background-color: #0003;
    z-index:999999;
    overflow: hidden;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    .modal-container{
        background-color: #fff;
        max-width: 600px;
        width: 100%;
        position: absolute;
        top: 50%;
        left: 55%;
        transform: translate(-50%, -50%);
        padding: 30px 50px;
        max-height: 90%;
        overflow: auto;
        border: .0625rem solid #0000;
        border-radius: 0.75rem;
        .modal-header{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 30px;
            h3{
                color: var(--main-color);
            }
            svg{
                margin-top: 5px;
                color: rgba(0, 0, 0, 0.441);
                cursor: pointer;
            }
        }
        .modal-body{
            .add-user-form{
                display: flex;
                flex-direction: column;
                gap: 20px;
                .modal-form-field{
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                    label{
                        color: #1e2022;
                        display: block;
                        font-size: .875rem;
                        font-weight: 400;
                        margin-bottom: 0.3rem;
                    }
                    input[type=text],input[type=date],input[type=time],input[type=number],textarea, select{
                        // padding: 10px;
                        // border: none;
                        // background-color: #D9D9D980;
                        background-clip: padding-box;
                        background-color: #fff;
                        border: 0.0625rem solid #e7eaf3;
                        border-radius: 0.3125rem;
                        color: #1e2022;
                        display: block;
                        font-size: .875rem;
                        font-weight: 400;
                        height: calc(1.6em + 1.21875rem);
                        line-height: 1.6;
                        padding: 0.54688rem 0.875rem;
                        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
                        width: 100%;
                        &:focus{
                            outline: 1px solid var(--main-color);
                        }
                    }
                }
                input[type=submit]{
                    width: max-content;
                    padding: 12px 24px;
                    background-color: #323082;
                    border: 1px solid;
                    color: #fff;
                    font-size: 16px;
                    cursor: pointer;
                    border-radius: 5px;
                    color: #fff;
                    font-size: 14px;
                    line-height: 17px;
                    text-transform: capitalize;
                    transition: all .2s ease-in-out;

                    &:hover{
                        background-color: #5A599B;
                    }
                }
            }
        }
    }
}

.new-modal-overlay{
    height: 100vh;
    width: 100%;
    .new-modal-container{
        background-color: #fff;
        width: 100%;
        max-height: 90%;
        padding: 30px 50px;
        border: .0625rem solid #0000;
        
        .modal-body{
            .add-user-form{
                display: flex;
                flex-direction: column;
                gap: 20px;
                .modal-form-field{
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                    label{
                        color: #1e2022;
                        display: block;
                        font-size: .875rem;
                        font-weight: 400;
                        margin-bottom: 0.3rem;
                    }
                    input[type=text],input[type=date],input[type=time],input[type=number],textarea, select{
                        background-clip: padding-box;
                        background-color: #fff;
                        border: 0.0625rem solid #e7eaf3;
                        border-radius: 0.3125rem;
                        color: #1e2022;
                        display: block;
                        font-size: .875rem;
                        font-weight: 400;
                        height: calc(1.6em + 1.21875rem);
                        line-height: 1.6;
                        padding: 0.54688rem 0.875rem;
                        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
                        width: 100%;
                        &:focus{
                            outline: 1px solid var(--main-color);
                        }
                    }
                }
                input[type=submit]{
                    width: max-content;
                    padding: 12px 24px;
                    background-color: #323082;
                    border: 1px solid;
                    color: #fff;
                    font-size: 16px;
                    cursor: pointer;
                    border-radius: 5px;
                    color: #fff;
                    font-size: 14px;
                    line-height: 17px;
                    text-transform: capitalize;
                    transition: all .2s ease-in-out;
                    &:hover{
                        background-color: #5A599B;
                    }
                }
            }
        }
    }
}