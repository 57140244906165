#dashboard{
    padding: 100px 27px 24px;
    .dashboard-cards{
        display: flex;
        gap: 30px;
        
        .dashboard-card{
            flex: 1;
            padding: 20px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            background-color: #fff;
            // box-shadow: 0px 4px 4px 0px #00000040;
            border-radius: 5px;
            border: 1px solid rgb(209, 209, 209);
            .dashboard-card-title{
                color: #334257;
                font-size: 14px;
                font-weight: 600;
                line-height: 19px;
            }
            .dashboard-card-data{
                font-size: 33px;
                font-weight: 800;
                color: #334257;
                span{
                    font-size: 18px;
                    color: #00000080;
                }
            }
            .dashboard-card-bottom{
                font-size: 15px;
                color: #00000080;
            }
        }
    }
    .dashboard-middle-cards{
        margin-top: 30px;
        display: flex;
        gap: 20px;
        .dashboard-middle-card{
            flex: 1;
            background-color: #fff;
            box-shadow: 0px 4px 4px 0px #00000040;
            width: 50%;
            .dashboard-middle-card-header{
                padding: 23px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 20px;
                height: 85px;
                .view-all-button{
                    border: 1px solid #AED2FF;
                    padding: 10px;
                    border-radius: 5px;
                    transition: .3s ease-in-out;
                    &:hover{
                        cursor: pointer;
                        background-color: #3086c328;
                    }
                }
                .legends{
                    display: flex;
                    gap: 10px;
                    .legend{
                        display: flex;
                        align-items: center;
                        gap: 5px;
                        font-size: 16px;
                        .legend-box{
                            height: 10px;
                            width: 10px;
                        }
                    }
                }
            }
            .dashboard-middle-card-body{
                padding: 23px;
                .upcoming-events{
                    .upcoming-event{
                        display: flex;
                        
                        
                        .event-date{
                            width: 70px;
                            padding: 10px 0px 10px 0px;
                            text-align: center;
                            border-right: 1px solid #00000033;
                            position: relative;
                            .event-dot{
                                position: absolute;
                                top: 40%;
                                right: -6px;
                                height: 10px;
                                width: 10px;
                                border-radius: 100%;
                                background-color: #F2E906;
                            }
                        }
                        .event-details{
                            padding: 10px 10px 10px 20px;
                            .event-title{
                                font-weight: 500;
                                font-size: 18px;
                            }
                            .event-time{
                                font-size: 15px;
                                color: #00000080;
                            }
                        }
                    }
                }
            }
        }
        
    }
    .bottom-cards{
        // display: flex;
        display: none;
        margin-top: 20px;
        gap: 30px;
        .bottom-card{
            flex: 1;
            background-color: white;
            padding: 20px;
            .bottom-card-header{
                font-size: 15px;
                letter-spacing: 2px;
                font-weight: 700;
                color: #030166;
                
            }
            .bottom-card-body{
                margin-top: 30px;
                .now-playing{
                    letter-spacing: 2px;
                    background-color: black;
                    color: white;
                    width: max-content;
                    font-weight: 500;
                    padding: 10px 20px;
                    font-size: 20px;
                    margin-bottom: 15px;
                }
                h3{
                    font-size: 25px;
                    margin-bottom: 23px;
                }
                audio{
                    &::-webkit-media-controls-panel{
                        background-color: white;
                    }
                    &::-webkit-media-controls-play-button{
                        border: 2px solid #FF0000; /* Set the border color and width */
            border-radius: 50%; /* Make the border a circle (optional) */
            width: 24px; /* Set the width of the border */
            height: 24px; /* Set the height of the border */
                    }
                }
            }
        }
    }
}

// LAPTOP VIEW
@media screen and (max-width: 1025px){
    #dashboard{
        .dashboard-cards{
            .dashboard-card{
                .dashboard-card-title{
                    font-size: 14px;
                }
                .dashboard-card-data{
                    font-size: 25px;
                    span{
                        font-size: 12px;
                    }
                }
                .dashboard-card-bottom{
                    font-size: 13px;
                }
            }
        }
        .bottom-cards{
            gap: 10px;
            .bottom-card{
                width: 33%;
                .bottom-card-header {
                    font-size: 15px;
                }
                .bottom-card-body{
                    overflow: auto;
                    .now-playing{
                        padding: 8px 14px;
                        font-size: 15px;
                        h3{
                            font-size: 19px;
                        }
                        audio{
                            width: -webkit-fill-available!important;
                        }
                    }
                }
            }
        }
    }
}

// MOBILE
@media screen and (max-width: 426px){
    #dashboard{

        .dashboard-cards{
            flex-direction: column;
            gap: 10px;
        }
        .dashboard-middle-cards{
            flex-direction: column;
            gap: 10px;
            .dashboard-middle-card{
                width: 100%;
            }
        }
        .bottom-cards{
            flex-direction: column;
            gap: 10px;
            .bottom-card{
                width: 100%;
            }
        }
    }
}