#footer{
    display: flex;
    background-color: #1E3161;
    padding: 50px;
    gap: 50px;
    .contact-details{
        color: white;
        .contact-detail{
            display: flex;
            align-items: center;
            gap: 10px;
            margin-bottom: 20px;
            svg{
                font-size: 25px;
            }
        }
    }
    .social-media-mail{
        .social-media-section{
            h4{
                color: white;
                font-weight: 500;
            }
            .social-media{
                margin-top: 10px;
                display: flex;
                justify-content: space-around;
                .social-medium{
                    height: 30px;
                    width: 30px;
                    border-radius: 100%;
                    background-color: #3A4D7C;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: white;
                }
            }
        }
        .send-mail-section{
            margin-top: 30px;
            h4{
                color: white;
                font-weight: 500;
                margin-bottom: 5px;
            }
            form{
                .form-row{
                    .form-col{
                        input, textarea{
                            width: 100%;
                            padding: 7px 10px;
                            margin-bottom: 5px;
                            border: 1px solid #eee;
                            &:focus{
                                outline: none;
                                border: 1px solid #77C9D7;
                            }
                        }
                    }
                }
                input[type=submit]{
                    padding: 10px;
                    border: none;
                    cursor: pointer;
                    background-color: #0562AE;
                    color: white;
                }
            }
        }
    }
    .footer-section{
        flex: 1;
        h4{
            color: white;
            margin-bottom: 10px;
            font-weight: 500;
        }
    }
}

// LAPTOP VIEW
@media screen and (max-width: 1025px){
    #footer{
        .contact-details{
            .contact-detail{
                .info{
                    font-size: 12px;
                }
                svg{
                    font-size: 15px;
                }
            }
        }
    }
}

// TABLET VIEW
@media screen and (max-width: 769px){
    #footer{
        flex-direction: column;
        gap: 30px;
    }
}