.swal2-container:not(.swal2-top):not(.swal2-top-left):not(.swal2-top-right):not(.swal2-center-left):not(.swal2-center-right):not(.swal2-bottom):not(.swal2-bottom-left):not(.swal2-bottom-right) > .swal2-modal {
  margin: auto;
  padding: 30px;
}
.swal2-icon.swal2-info {
  font-size: 20px!important;  
}
.no-data{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 20px;
  color: #677788;
}

.no-data img{
  width: 400px;
}

.form--control{
  background-clip: padding-box;
  background-color: #fff;
  border: 0.0625rem solid #e7eaf3;
  border-radius: 0.3125rem;
  color: #1e2022;
  display: block;
  font-size: .875rem;
  font-weight: 400;
  height: calc(1.6em + 1.21875rem);
  line-height: 1.6;
  padding: 0.54688rem 0.875rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  width: 100%;
}

.text-danger {
  color: #ed4c78!important;
}

.action-btn {
  align-items: center;
  border: 1px solid #0177cd;
  border-radius: 5px;
  box-shadow: none!important;
  color: #0177cd;
  display: flex;
  font-size: 1em!important;
  height: 30px;
  justify-content: center;
  padding: 0!important;
  transition: all .3s ease;
  width: 30px;
}

.action-btn svg{
  color: #0177cd;
}

.action-btn:hover{
  background-color: #0177cd;
}

.action-btn:hover svg{
  color: #fff;
}

.danger-btn {
  align-items: center;
  border: 1px solid #ed4c78!important;
  border-radius: 5px;
  box-shadow: none!important;
  color: #ed4c78!important;
  display: flex;
  font-size: 1em!important;
  height: 30px;
  justify-content: center;
  padding: 0!important;
  transition: all .3s ease;
  width: 30px;
}

.danger-btn svg{
  color: #ed4c78!important;
}

.danger-btn:hover{
  background-color: #ed4c78;
}

.danger-btn:hover svg{
  color: #fff!important;
}

@media screen and (min-width: 1650px){
  .action-btn {
      height: 32px;
      width: 32px;
  }
}

.custom--row{
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.input-date-group{
  position: relative;
  width: 300px;
}

.input-date-group .input-label {
  font-size: 14px;
  padding: 0 15px 0 4px;
  z-index: 2;
  position: absolute;
  background-color: #fff;
  top: -10px;
  left: 10px;
}

.input-label {
  color: #1e2022;
  display: block;
  font-size: .875rem;
  text-transform: capitalize;
  margin-bottom: 0.5rem;
}

.w-100{
  width: 100%!important;
}