#profit-loss-page{
    .filtering-grounds{
        form{
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 20px;
            .form-control{
                display: flex;
                // flex-direction: column;
                gap: 2px;
                align-items: center;
                width: 25%;
                label{
                    width: 40%;
                }
                
                input[type=date]{
                    border: 1px solid #87878740;
                    padding: 5px;
                    width: 100%;
                    
                    &:focus{
                        outline: 1px solid var(--main-color);
                    }
                }
            }
            input[type=submit]{
                width: max-content;
                padding: 10px;
                background-color: var(--main-color);
                border: none;
                color: #fff;
                font-size: 16px;
                font-weight: 200;
                cursor: pointer;
            }
        }
    }
    .profit-loss-statement{
        margin-top: 30px;
        .profit-loss-table{
            border-collapse: collapse;
            width: 70%;
            margin: auto;
            thead{
                border-radius: 10px !important;
                tr{
                    th{
                        padding: 10px 20px;
                        text-align: right;
                        border: 1px solid #D9D9D9;
                    }
                }
            }
            tbody{
                background-color: white;
                tr{
                    td{
                        padding:10px 20px;
                        cursor: default;
                        border: 1px solid #D9D9D9;
                    }
                    .member{
                        display: flex;
                        align-items: center;
                        gap: 5px;
                        img{
                            height: 35px;
                            width: 35px;
                            border-radius: 50%;
                        }
                    }
                    .actions{
                        svg{
                            margin-right: 5px;
                            font-size: 20px;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
}