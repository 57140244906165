.settings-details{
    .church-form{
        margin-top: 20px;
        .profile-picture-wrapper{
            display: flex;
            gap: 20px;
            margin-bottom: 30px;
            .church-logo{
                -webkit-box-shadow: -1px 12px 22px -7px rgba(0,0,0,0.11);
                -moz-box-shadow: -1px 12px 22px -7px rgba(0,0,0,0.11);
                box-shadow: -1px 12px 22px -7px rgba(0,0,0,0.11);
                padding:10px;
                border-radius: 10px;
                max-height: 135px;
            }
            .picture-details{
                h3{
                    font-size: 20px;
                    margin-bottom: 10px;
                }
                .upload-button{
                    background-color: var(--main-color);
                    width: max-content;
                    padding: 8px;
                    color: white;
                    border-radius: 5px;
                    &:hover{
                        cursor: pointer;
                    }
                }
                .info{
                    margin-top: 10px;
                    color: #00000080;
                    font-size: 13px;
                }
            }
        }
        .form-field-row{
            display: flex;
            gap: 20px;
        }
        .form-field{
            display: flex;
            flex-direction: column;
            width: 50%;
            margin-bottom: 10px;
            input,select, textarea{
                padding: 10px;
                color: #00000080;
            }
            small{
                color: #adadad;
            }
        }
        input[type=submit]{
            border: none;
        }
        hr{
            margin-top: 10px;
        }
    }
}

@media screen and (max-width: 426px){
    .form-field-row{
        flex-direction: column;
    }
    .form-field{
        width: 100% !important;
    }
}