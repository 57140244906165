#login{
    display: flex;
    height: 100vh;
    .left-login{
        flex: 1;
        background: url('../../assets/loginImage.jpeg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
    
    .right-login{
        flex: 1;
        padding: 35px 122px;
        display: flex;
        align-items: center;
        justify-content: center;
        .right-login-container{
            display: flex;
            flex-direction: column;
            gap: 30px;
            width: 80%;
            .login-text{
                font-size: 30px;
                font-weight: 400;
                color: #030166;
            }
            .login-form-container{
                .welcome-text{
                    font-size: 25px;
                    font-weight: 400;
                }
                p{
                    color: rgba(0,0,0,0.5);
                    font-size: 18px;
                }
                .login-form{
                    margin-top: 20px;
                    .form-input{
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                        margin-bottom: 20px;
                        label{
                            padding: 5px;
                            border-left: 5px solid #030166;
                        }
                        input{
                            padding: 15px 12px;
                            background-color: #D9D9D966;
                            border: none;
                            outline: none;
                            &:focus{
                                outline: 1px solid rgb(48, 48, 48);
                            }
                        }
                    }
                    .form-bottom{
                        margin-top: 40px;
                        display: flex;
                        justify-content: space-between;
                        font-size: 18px;
                        .remember-me{
                            color: #00000080;
                            input{
                                margin-right: 5px;
                            }
                        }
                        a{
                            color: #030166;
                        }
                    }
                    input[type=submit]{
                        width: 100%;
                        padding: 10px;
                        background-color:#030166;
                        border: none;
                        color: white;
                        margin-top: 34px;
                        font-size: 20px;
                        font-weight: 400;
                        &:hover{
                            background-color: #110f78;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
        
    }
}

// LAPTOP VIEW
@media screen and (max-width: 1025px){
    #login{
        .right-login{
            padding: 15px 80px;
            .right-login-container{
                gap: 10px;
                width: 100%;
                .login-text{
                    font-size: 20px;
                }
                .login-form-container{
                    .welcome-text{
                        font-size: 25px;
                    }
                    p{
                        font-size: 15px;
                    }
                    .login-form{
                        .form-input{
                            label{
                                padding: 5px;
                            }
                            input{
                                padding: 12px 10px;
                            }
                        }
                        .form-bottom{
                            margin-top: 20px;
                            font-size: 15px;
                        }
                    }
                }
            }
        }
    }
}

// TABLET VIEW
@media screen and (max-width: 769px){
    #login{
        .right-login{
            .login-form-container{
                .welcome-text{
                    font-size: 20px;
                }
                p{
                    font-size: 12px;
                }
            }
        }
    }
}

// MOBILE VIEW
@media screen and (max-width: 426px){
    #login{
        .left-login{
            display: none;
        }
        .right-login{
            padding: 15px 50px;
        }
    }
}