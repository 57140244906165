#finance{
    padding: 100px 27px 24px;
    min-height:86vh;
    .finance-main-page{

        .overview-page{
            display: flex;
            gap: 24px;
            .overview-left{
                flex: 2;
                .finance-cards{
                    display: flex;
                    gap: 20px;
                    .finance-card{
                        flex: 1;
                        box-shadow: 0px 4px 4px 0px #00000040;
                        background-color: white;
                        padding: 6px 18px;
                        border-radius: 10px;
                        display: flex;
                        flex-direction: column;
                        gap: 5px;
                        .finance-card-icon{
                            background-color: #EEEFFE;
                            padding: 11px;
                            width: max-content;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            svg{
                                color: #5A66F1;
                                font-size: 19px;
                            }
                        }
                        .finance-card-title{
                            font-size: 18px;
                            color: #00000080;
                        }
                        .finance-card-details{
                            span{
                                font-weight: 500;
                                font-size: 18px;
                            }
                        }
                    }
                }
                .stats-chart{
                    padding: 10px 23px;
                    margin-top: 30px;
                    box-shadow: 0px 4px 4px 0px #00000040;
                    background-color: white;
                    .title{
                        font-size: 18px;
                        font-weight: 600;
                    }
                }
            }
            .overview-right{
                flex: 1;
                .overview-right-card{
                    box-shadow: 0px 4px 4px 0px #00000040;
                    background-color: white;
                    padding: 27px 18px;
                    border-radius: 10px;
                    margin-bottom: 25px;
                    h3{
                        margin-bottom: 20px;
                    }
                    .transaction{
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-end;
                        .details{
                            p{
                                color: #00000080;
                                font-size: 14px;
                            }
                        }
                        .amount{
                            color: #00000080;
                            font-size: 14px;
                        }
                    }
                }
            }
        }
        .income-page{
            .add-income{
                display: flex;
                justify-content: flex-end;
                margin-bottom: 10px;
            }
        }
    }
}

@media screen and (max-width: 1025px){
    #finance{
        .finance-main-page{
            .overview-page{
                flex-direction: column;
                align-items: center;
                .overview-left{
                    width: 100%;
                    .finance-cards{
                        flex-direction: column;
                        gap: 8px;
                    }
                }
            }
        }
    }
}