#leadership-page {
  .top-image {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .meet-our-founders {
    padding: 90px 40px;
    .title {
      color: #030166;
      font-size: 20px;
      text-align: center;
    }
    .founders {
      margin-top: 50px;
      display: flex;
      gap: 50px;
      justify-content: center;
      .founders-one {
        flex: 1;
        position: relative;
        img {
          position: absolute;
        }
        .wife-img {
          bottom: 0;
          right: 30px;
        }
        .pastor-img {
          left: 75px;
        }
      }
      .founders-two {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .founders-names {
      text-align: center;
      text-transform: uppercase;
      font-weight: 400;
      font-size: 40px;
      margin-top: 50px;
    }
  }
  .about-founder-section {
    background-color: #f8f8f8b2;
    padding: 90px 170px;
    display: flex;
    gap: 40px;
    margin-bottom: 100px;
    .description {
      margin-top: 75px;
      flex: 1;
      .description-top {
        .founder-name {
          font-size: 30px;
          font-weight: 400;
        }
        .underline {
          height: 3px;
          background-color: #030166;
          width: 170px;
          margin-top: 10px;
          margin-bottom: 20px;
        }
      }
      .description-details {
        .introduction {
          font-size: 20px;
          margin-bottom: 10px;
        }
        .about-founder {
          font-size: 18px;
          color: #00000080;
          text-align: justify;
          width: 90%;
          line-height: 33px;
        }
      }
    }
    .founder-img {
      flex: 1;
      display: flex;
      align-items: flex-start;
      justify-content: center;
    }
  }
  .executive-council {
    padding: 10px 170px 100px;
    .header {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;
      .title {
        color: #003177;
        font-size: 20px;
      }
      .underline {
        background-color: #efc940;
        width: 80px;
        height: 3px;
      }
    }
    .council-members {
      margin-top: 25px;
      display: flex;
      flex-wrap: wrap;
      gap: 100px;
      .council-member {
        flex: 1;
        width: 50%;
        display: flex;
        align-items: center;
        gap: 10px;
        .member-name-position {
          .member-position {
            font-size: 20px;
            color: #003177;
            margin-bottom: 10px;
            font-weight: 500;
          }
          .member-name {
            color: #00000080;
            font-size: 18px;
          }
        }
      }
    }
  }
}

// MEDIA QUERIES
@media screen and (max-width: 1025px) {
  #leadership-page {
    .meet-our-founders {
      .founders {
        .founders-one {
          .pastor-img {
            height: 300px;
            top: -30px;
            left: 20px;
          }
          .wife-img {
            height: 254px;
          }
        }
        .founders-two {
          img {
            height: 300px;
          }
        }
      }
      .founders-names {
        font-size: 25px;
      }
    }
    .about-founder-section {
      padding: 20px 50px;
    }
    .executive-council {
      padding: 10px 50px 100px;
      .council-members {
        gap: 30px;
        .council-member {
          .member-img {
            img {
              height: 200px;
            }
          }
          .member-name-position {
            .member-position {
              font-size: 18px;
            }
            .member-name {
              font-size: 15px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  #leadership-page {
    .meet-our-founders {
      .founders {
        .founders-one {
          .pastor-img {
            left: -84px;
          }
          .wife-img {
            right: -8px;
          }
        }
        .founders-two {
          img {
            height: 300px;
          }
        }
      }
      .founders-names {
        font-size: 25px;
      }
    }
    .about-founder-section {
      align-items: center;
      .description {
        margin-top: 0px;
        .description-top {
          .founder-name {
            font-size: 20px;
          }
        }
        .description-details {
          .about-founder {
            font-size: 16px;
            width: 100%;
            line-height: 24px;
          }
        }
      }
      .founder-img {
        img {
          height: 287px;
        }
      }
    }
    .executive-council {
      .council-members {
        .council-member {
          .member-img {
            img {
              height: 110px;
            }
          }
          .member-name-position {
            .member-position {
              font-size: 18px;
            }
            .member-name {
              font-size: 15px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 426px) {
  #leadership-page {
    .meet-our-founders {
      .founders {
        .founders-one {
          display: none;
        }
      }
    }
    .about-founder-section {
      align-items: center;
      flex-direction: column;
    }
  }
}
