#navbar {
  padding: 14px 17px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .logo {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 5px;
    img {
      height: 80px;
    }
    .church-name {
      color: #030166;
      font-size: 19.2px;
      font-weight: 600;
    }
  }
  .navigation {
    flex: 4;
    .menu-list {
      display: flex;
      justify-content: space-around;
      .navigation-item {
        .nav-link-item {
          color: #030166;
          &:hover {
            cursor: pointer;
          }
        }
        .active {
          color: #001c43;
          font-weight: 600;
        }
      }
    }
  }
  .donate-button {
    background-color: #030166;
    padding: 15px 45px;
    border-radius: 15px;
    width: max-content;
    color: white;
    &:hover {
      cursor: pointer;
    }
  }
  #mobile {
    display: none;
  }
}

// LAPTOP VIEW
@media screen and (max-width: 1025px) {
  #navbar {
    .logo {
      img {
        height: 50px;
      }
      .church-name {
        font-size: 12px;
      }
    }
    .navigation {
      font-size: 13px;
    }
    .donate-button {
      padding: 9px 17px;
      border-radius: 8px;
      font-size: 12px;
    }
  }
}

// TABLET VIEW
@media screen and (max-width: 769px) {
  #navbar {
    .logo {
      img {
        height: 40px;
      }
      .church-name {
        font-size: 10px;
      }
    }
    .navigation {
      font-size: 11px;
    }
    .donate-button {
      padding: 6px 9px;
      border-radius: 8px;
      font-size: 10px;
    }
  }
}

// MOBILE VIEW
@media screen and (max-width: 426px) {
  #navbar {
    .logo {
      img {
        height: 40px;
      }
      .church-name {
        font-size: 10px;
      }
    }
    .navigation {
      flex: 1;
      padding-top: 21px;
      .menu-list {
        display: flex;
        flex-direction: column;
        align-items: inherit;
        justify-content: flex-start;
        position: fixed;
        top: 70px;
        right: -340px;
        width: 250px;
        height: 100vh;
        background: #1f1f1f;
        box-shadow: 0 40px 60px rgba(0, 0, 0, 0.1);
        padding: 40px 0 0 20px;
        transition: 0.3s ease-in-out;
        z-index: 9999999;
        .navigation-item {
          margin: 0 25px;
          font-size: 14px;
          font-weight: 700;
          padding: 17px 0;
          text-transform: uppercase;
          border-bottom: 1px solid #626262;
          .nav-link-item {
            color: #979797;
          }
          .active{
            color: #0059d5;
          }
        }
      }
      .active-navbar {
        right: 0px;
      }
    }
    .donate-button {
      display: none;
    }
    #mobile {
      display: block;
    }
  }
}
