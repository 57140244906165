#member-management{
    padding: 100px 27px 24px;
    .member-management-top{
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #0000001A;
        margin-bottom: 30px;
        .member-management-tabs{
            display: flex;
            .member-management-tab{
                padding: 12px 44px;
                color: #00000080;
                &:hover{
                    cursor: pointer;
                    background-color: white;
                    color: #000000;
                }
            }
            .active-tab{
                background-color: white;
                color: #000000;
            }
        }   
            
    }
}

.table-container{
    box-shadow: 0 6px 12px rgba(140,152,164,.075);
    word-wrap: break-word;
    background-clip: initial;
    background-color: #fff;
    border: 0.0625rem solid #e7eaf3b3;
    border-radius: 0.75rem;
    display: flex;
    flex-direction: column;
    min-width: 0;
    position: relative;
    .table-body{
        flex: 1 1 auto;
        min-height: 1px;
        padding: 1.3125rem;
        .table-top{
            background: #fcfcfc;
            border-radius: 5px 5px 0 0;
            padding: 20px 15px;
            .table--header{
                align-items: center;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                width: 100%;
                .input--group{
                    align-items: stretch;
                    display: flex;
                    flex-wrap: wrap;
                    position: relative;
                    width: 20%;
                    .form--control{
                        flex: 1 1 auto;
                        margin-bottom: 0;
                        position: relative;
                        border-color: #0177cd4d;
                        height: 40px;
                        background-clip: padding-box;
                        background-color: #fff;
                        border: 0.0625rem solid #e7eaf3;
                        border-radius: 0.3125rem;
                        color: #1e2022;
                        display: block;
                        font-size: .875rem;
                        font-weight: 400;
                        line-height: 1.6;
                        padding: 0.54688rem 0.875rem;
                        transition: border-color .15s
                    }
                }
            }
        }
    }
}

.default-table{
    overflow: auto;
    table{
        border-collapse: collapse;
        width: 100%;
        thead{
            border-radius: 10px !important;
            // background-color: var(--main-color);
            background-color: #e9f2ff;
            tr{
                th{
                    border-bottom: 0.0625rem solid #e7eaf3b3;
                    border-top: 0.0625rem solid #e7eaf3b3;
                    padding: 10px 20px;
                    color: #677788;
                    text-align: left;
                }
            }
        }
        tbody{
            background-color: white;
            tr{
                td{
                    padding:10px 20px;
                    cursor: default;
                    border-bottom: 1px solid #D9D9D9;
                }
                .member{
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    img{
                        height: 35px;
                        width: 35px;
                        border-radius: 50%;
                    }
                }
                .actions{
                    display: flex;
                    gap: 10px;
                    svg{
                        flex: 0 0 1.75rem;
                        font-size: 1.125rem;
                        line-height: 1.4;
                        opacity: .7;
                    }
                }
            }
        }
    }
    .pagination{
        display: flex;
        align-items: center;
        justify-content: right;
        padding: 20px;
        .pagination-page,.pagination-previous-page,.pagination-next-page{
            background-color: #3877bf99;
            padding: 5px 10px;
            color: white;
            cursor: pointer;
            border: 1px solid #5272F2;
        }
        .pagination-active-page{
            background-color: #5272F2;
            cursor: default;
        }
        
    }
}

// MOBILE VIEW
@media screen and (max-width: 426px){
    #member-management{
        .member-management-top{
            .member-management-tabs{
                .member-management-tab{
                    padding: 12px 20px;
                    font-size: 12px;
                }
            }
        }
    }
}