#about-page{
    padding: 90px 70px;
    .about-us{
        display: flex;
        flex-wrap: wrap;
        align-content: stretch;
        gap: 90px;
        justify-content: center;
        align-items: center;
        .about-carousel{
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .about-text{
            flex: 1;
            h3{
                margin-bottom: 30px;
            }
            p{
                margin-bottom: 50px;
                text-align: justify;
                font-size: 18px;
                color: #00000080;
            }
        }
    }
    .our-leaders{
        margin-top: 90px;
        h3{
            text-align: center;
        }
        .leaders{
            margin-top: 60px;
            display: flex;
            flex-wrap: wrap;
            gap: 25px;
            align-items: center;
            justify-content: center;
            .leader{
                
                .leader-details{
                    border: 0.5px solid #03016666;
                    text-align: center;
                    padding: 17px;
                    .name{
                        font-weight: 500;
                        font-size: 20px;
                    }
                    .position{
                        font-size: 16px;
                        color: #00000080;
                    }
                }
            }
        }
    }
}

// TABLET VIEW
@media screen and (max-width: 768px) {
    #about-page{
        .our-leaders{
            .leaders{
                .leader{
                    .leader-img{
                        img{
                            height: 200px;
                        }
                    }
                    .leader-details{
                        padding: 10px;
                        .name{
                            font-size: 18px;
                        }
                        .position{
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
}


// MOBILE VIEW
@media screen and (max-width: 426px) {
    #about-page{
        padding:30px 40px;
        .about-us{
            flex-direction: column;
            gap: 20px;
            .about-carousel{
                width: 100%;
                img{
                    width: 100%;
                }
            }
            .about-text{
                h3{
                    margin-bottom: 10px;
                }
                p{
                    margin-bottom: 13px;
                    font-size: 14px;
                }
            }
        }
        .our-leaders{
            .leaders{
                .leader{
                    .leader-img{
                        img{
                            height: 200px;
                        }
                    }
                    .leader-details{
                        padding: 10px;
                        .name{
                            font-size: 18px;
                        }
                        .position{
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
}