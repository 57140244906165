#add-member {
  padding: 100px 27px 24px;
  min-height: 86vh;
  .add-member-title {
    color: var(--main-color);
    font-weight: 600;
    margin-bottom: 20px;
  }
  .add-member-form {
    border: 1px solid #d9d9d9;
    .member-form-section {
      background-color: white;
      .member-form-section-header {
        padding: 11px;
        color: #fff;
        background-color: var(--main-color);
      }
      .personal-info-section {
        display: flex;
        gap: 12px;
        .personal-info-details {
          flex: 5;
        }
        .personal-info-image {
          flex: 1;
          display: flex;
          flex-direction: column;
          margin-right: 20px;
          .image-container {
            max-height: 210px;
            max-width: 210px;
            img {
              height: 100%;
              width: 100%;
            }
          }
          input[type="file"] {
            display: none;
          }
          #upload-button {
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 12px;
            margin-top: 10px;
            width: 100%;
            padding: 7px 13px;
            background-color: var(--main-color);
            &:hover {
              cursor: pointer;
              background-color: var(--main-color-hover);
            }
          }
        }
      }

      .member-form-body {
        padding: 12px 12px 60px;
        .member-form-details {
          display: flex;
          flex-direction: column;
          gap: 15px;
          .member-form-row {
            display: flex;
            gap: 80px;
            justify-content: space-between;
            .member-form-column {
              display: flex;
              flex-direction: column;
              gap: 5px;
              flex: 1;
              label {
                color: #1e2022;
                display: block;
                font-size: 0.875rem;
              }
              input[type="text"],
              input[type="email"],
              input[type="date"] {
                background-clip: padding-box;
                background-color: #fff;
                border: 0.0625rem solid #e7eaf3;
                border-radius: 0.3125rem;
                color: #1e2022;
                display: block;
                font-size: 0.875rem;
                font-weight: 400;
                height: calc(1.6em + 1.21875rem);
                line-height: 1.6;
                padding: 0.54688rem 0.875rem;
                transition: border-color 0.15s ease-in-out,
                  box-shadow 0.15s ease-in-out;
                width: 100%;
                margin-bottom: 0.5rem;
                &:focus {
                  outline: 1px solid var(--main-color);
                }
              }
              .radio-row {
                display: flex;
                gap: 40px;
                font-size: 20px;
                color: #28282880;
                .radio-item {
                  display: flex;
                  align-items: center;
                  gap: 5px;
                  input[type="radio"] {
                    height: 20px;
                  }
                }
              }
            }
          }
          .add-more-child-button {
            background-color: #d9d9d917;
            text-align: center;
            border: 2px dashed #87878740;
            font-size: 15px;
            padding: 24px;
            color: #28282880;
            font-weight: 600;
            &:hover {
              cursor: pointer;
              background-color: #d9d9d93a;
            }
          }
        }
        .dependant-form-details {
          border: 1px solid #87878740;
          padding: 23px 12px;
          .remove-child-button {
            margin-top: 15px;
            background-color: #a7312180;
            text-align: center;
            border: 2px dashed #a7312169;
            font-size: 15px;
            padding: 20px;
            color: #fff;
            font-weight: 600;
            transition: 0.3s ease-in-out;
            &:hover {
              cursor: pointer;
              background-color: #a73121c8;
            }
          }
        }
      }
    }
    .continue-button {
      display: flex;
      justify-content: flex-end;
      padding: 12px;
      gap: 30px;
    }
  }
}

@media screen and (max-width: 1024px) {
  #add-member {
    .add-member-form {
      overflow: scroll;
      background-color: white;
      .member-form-section {
        .member-form-body {
          .member-form-details {
            .member-form-row {
              gap: 12px;
              .member-form-column {
                .radio-row {
                  gap: 19px;
                  .radio-item {
                    font-size: 14px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 426px) {
  #add-member {
    .add-member-form {
      overflow: scroll;
      background-color: white;
      .member-form-section {
        .member-form-body {
          .member-form-details {
            .member-form-row {
              .member-form-column {
                label {
                  font-size: 12px;
                }
                .radio-row {
                  gap: 19px;
                  .radio-item {
                    font-size: 14px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
