#department-page{
    padding: 40px;
    .departments{
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        .department{
            flex-basis: 32%;
            flex-grow: 1;
            background: url("../../assets/DepartmentImages/departmentImage.png");
            background-size: cover;
            background-position: center;
            padding: 18px;
            height: 450px;
            color: white;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
        }
    }
}

@media screen and (max-width: 1025px){
    #department-page{
        .departments{
           
            .department{
                
                height: 300px;
                
            }
        }
    }
}

@media screen and (max-width: 426px){
    #department-page{
        .departments{
           flex-direction: column;
            .department{
                flex-basis: 300px;
                
            }
        }
    }
}