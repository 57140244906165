#contact-us-page{
    padding: 40px;
    display: flex;
    gap: 100px;
    .contact-row{
        flex: 1;
        .row-title{
            margin-bottom: 10px;
        }
        .contact-form{
            
            .form-input{
                margin-bottom: 10px;
                display: flex;
                flex-direction: column;
                gap: 7px;
                input, textarea{
                    padding: 7px;
                }
            }
        }
    }
}

@media screen and (max-width: 1025px){
    #contact-us-page{
        gap: 20px;
    }
}

@media screen and (max-width: 815px){
    #contact-us-page{
        gap: 50px;
        flex-direction: column;
    }
}


@media screen and (max-width: 426px){
    #contact-us-page{
        padding: 20px;
        .first-row{
            display: flex;
            gap: 20px;
            flex-direction: column;
            
        }
    }
}