#user-management{
    padding: 100px 27px 24px;
    .user-list-card{
        
        background-color: white;
        padding: 20px;
        background-clip: initial;
        background-color: #fff;
        border: 0.0625rem solid #e7eaf3b3;
        border-radius: 0.75rem;
        
        .user-list-header{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 1rem!important;
            h4{
                color: #1e2022;
                font-size: 1.2rem;
            }
            .add-user-button{
                display: flex;
                align-items: center;
                gap: 5px;
                padding: 10px;
                border-radius: 8px;
                background-color: #375BF2;
                color: white;
                transition: .3s ease-in-out;
                &:hover{
                    background-color: #375cf2e0;
                    cursor: pointer;
                }
            }
        }
        .user-list-table{
            width: 100%;
            overflow-x: auto;
            table{
                width: 100%;
                border-collapse: collapse;
                thead{
                    background-color: #FAFBFF;
                    tr{
                        th{
                            padding: .932rem 1.3125rem;
                            color: #4B4D69;
                            text-align: left;
                            font-size: 14px;
                            font-weight: 600;
                            border-bottom: 0.0625rem solid #e7eaf3b3;
                            border-top: 0.0625rem solid #e7eaf3b3;
                            text-transform: capitalize;
                            vertical-align: bottom;
                            text-align: -webkit-match-parent;
                        }
                    }
                }
                tbody{
                    tr{
                        border-bottom: 1px solid rgba(128, 128, 128, 0.317);
                        td{
                            padding: 13px 10px;
                        }
                        .user{
                            display: flex;
                            align-items: center;
                            gap: 5px;
                            img{
                                height: 40px;
                                width: 40px;
                                border-radius: 50%;
                                object-fit: cover;
                            }
                            .user-details{
                                display: flex;
                                flex-direction: column;
                                gap: 1px;
                                .full-name{
                                    font-weight: 600;
                                }
                                .username{
                                    color: #292f377d;
                                }
                            }
                        }
                        .actions{
                            svg{
                                margin-right: 5px;
                                font-size: 20px;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    #user-management{
        .user-list-card{
            .user-list-table{
                overflow: scroll;
            }
        }
    }
}