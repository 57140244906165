.timer{
    display: flex;
    color: #fff;
    gap: 14px;
    align-items: center;
    .timer-section{
        display: flex;
        flex-direction: column;
        align-items: center;
        h3{
            font-size: 40px;
            font-weight: 900;
        }
        p{
            font-size: 15px;
        }
    }
    .timer-separator{
        margin-bottom: 25px;
        font-size: 50px;
        font-weight: 900;
    }
}