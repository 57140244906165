#topbar{
    padding: 9px 20px 9px 2rem;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 5px 10px -2px #0000001a;
    position: fixed;
    width: 80%;
    .topbar-title{
        .menu-icon{
            display: none;
        }
        
        color: #030166;
        font-size: 16px;
        font-weight: 400;
        text-transform: uppercase;
    }
    .topbar-info{
        display: flex;
        align-items: center;
        gap: 7px;
        text-transform: capitalize;
        justify-content: flex-start;
        svg{
            font-size: 30px;
        }
        .avatar-image{
            img{
                height: 40px;
                border-radius: 50%;
            }
        }
    }
}

@media screen and (max-width: 769px) {
    #topbar{
        width: 100%;
        .topbar-title{
            .menu-icon{
                display: inline;
                margin-right: 10px;
            }
            
        }
    }
}