#year-theme{
    height: 50vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    .theme-title{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        h3{
            font-size: 38.65px;
            color: #E52F16;
        }
        .underline{
            height: 2px;
            width: 310px;
            background-color: #03016640;
        }
    }
    .theme{
        width: 60%;
        font-size: 30px;
        text-align: center;
    }
}

#mission-vision-believes{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 100px 150px;
    .mission-vision-title{
        color: #030166;
    }
    .mission-vision{
        display: flex;
        gap: 80px;
        padding: 50px 0px;
        .mission-vision-item{
            border-radius: 5px;
            padding: 42px 17px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 10px;
            color: #00000080;
            font-size: 20px;
            background: linear-gradient(to bottom,#63AEE5 5%, #F0F4F700 70%);
            .mission-vision-item-title{
                color: #030166;
            }
            .mission-vision-item-body{
                letter-spacing: 0.25px;
                line-height: 30px;
            }
        }
    }
    .believes{
        width: 100%;
        border-radius: 5px;
        padding: 42px 17px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
        color: #00000080;
        font-size: 20px;
        background: linear-gradient(to bottom,#63AEE5 5%, #F0F4F700 70%);
        .believes-title{
            color: #030166;
        }
        .believes-list{
            list-style-type: disc;
            margin-left: 20px;
            li{
                margin-bottom: 10px;
            }
        }
    }
}

#welcome-message{
    padding: 57px 100px 57px 151px;
    background-color: #F0F4F7;
    display: flex;
    
    gap: 100px;
    .welcome-message-left{
        flex: 2;
        .pastor-img{
            position: relative;
        }
        .welcome-box{
            position: absolute;
            bottom: 5px;
            right: 0;
            height: 200px;
            width: 200px;
            background-color: #50597D;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 25px;
            padding: 16px;
        }
    }
    .welcome-message-right{
        flex: 1.5;
        padding-top: 30px;
        .welcome-title{
            h3{
                letter-spacing: 14.25px;
                margin-bottom: 10px;
            }
            h2{
                font-size: 25px;
                color: #030166;
            }
        }
        .welcome-text{
            margin: 35px auto;
            .greeting{
                font-size: 25px;
                font-weight: 500;
            }
            .message{
                font-size: 20px;
                color: #000000a0;
            }
        }
        .welcome-about{
            display: flex;
            gap: 60px;
            .bar{
                width: 5px;
                background-color: red;
                
            }
            .welcome-about-text{
                color: #00000080;
                font-size: 20px;
            }
        }
        .meet-pastor-button{
            margin-top: 120px;
            background-color: #030166;
            color: white;
            border-radius: 8px;
            padding: 14px;
            width: max-content;
        }
    }
}

#sermons{
    margin-top: 100px;
    padding: 57px 151px 57px 151px;
    background-color: #F0F4F7;
    .sermons-title{
        color: #030166;
        margin-bottom: 50px;
        font-weight: 700;
    }
    .latest-sermon{
        .latest-sermon-top{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 22px;
            h3{
                font-size: 30px;
                font-weight: 900;
            }
            .access-more{
                font-size: 18px;
                font-weight: 500;
                display: flex;                
                align-items: center;
                justify-content: center;
                gap: 5px;
            }
        }
        .latest-sermon-item{
            display: flex;
            background-color: white;
            align-items: center;
            gap: 25px;
            .latest-sermon-thumbnail{
                height: 400px;
                img{
                    height: inherit;
                }
            }
            .sermon-main{
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 50px;
                padding: 50px;
                .now-playing-button{
                    background-color: black;
                    color: white;
                    padding: 10px;
                    width: max-content;
                }
                .sermon-title{
                    font-size: 36px;
                }
            }
        }
    }
    .other-sermons{
        margin-top: 80px;
        background-color: #D9D9D933;
        padding: 41px 20px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        .other-sermon-item{
            background-color: white;
            display: flex;
            align-items: center;
            .other-sermon-thumbnail{
                height: 100px;
                flex: 1;
                img{
                    height: inherit;
                    object-fit: cover;
                    width: -webkit-fill-available;
                }
            }
            .other-sermon-detail{
                padding: 0px 10px;
                flex: 1;
            }
            .other-sermon-audio{
                flex: 1;
                padding: 0px 30px;
            }
        }
    }
}

#upcoming-event{
    padding: 70px 0px;
    background: url("../../assets/upcomingEvent.png");
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    .upcoming-event-title{
        color: white;
    }
    .event-title, .event-date{
        font-size: 50px;
        width: 50%;
        margin: 0px auto;
        color: white;
        text-align: center;
        margin-bottom: 20px;
    }
    .more-events-button{
        margin-top: 20px;
        background-color:black;
        padding: 20px;
        color: white;
        border: 1px solid black;
        transition: .3s ease-in-out;
        &:hover{
            cursor: pointer;
            background:rgba(0, 0, 0, 0.865) ;
        }
    }
}   

#how-we-worship{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
    gap: 200px;
    .worship-text{
        font-size: 32px;
        width: 50%;
        color: #0B1725;
    }
    .learn-more-button{
        border: 2px solid black;
        padding: 16px 20px;
        cursor: pointer;
    }
}

// LAPTOP VIEW
@media screen and (max-width: 1025px){
    #mission-vision-believes {
        padding: 50px 80px;
    }
    #welcome-message{
        padding: 57px 100px;
        gap: 40px;
        .welcome-message-left{
            .pastor-img{
                height:100%;
                img{
                    height: 100%;
                    width: 100%;
                }
            }
        } 
        .welcome-message-right{
            .welcome-title{
                h3 {
                    letter-spacing: 10.25px;
                }
                h2{
                    font-size: 20px;
                }
            } 
            .welcome-text{
                .greeting {
                    font-size: 19px;
                }
                .message{
                    font-size: 13px;
                }
            } 
            .welcome-about{
                gap: 12px;
                .welcome-about-text{
                    font-size: 20px;
                }
            }
        } 
    } 
    #sermons{
        padding: 57px;
    }
    #upcoming-event{
        .event-title, .event-date{
            width: 60%;
            font-size: 35px;
        }
    }
}

// TABLET VIEW
@media screen and (max-width: 768px){
    #year-theme{
        .theme {
            width: 78%;
            font-size: 26px;
        }
    } 
    #mission-vision-believes {
        padding: 50px 30px;
        .mission-vision{
            gap: 30px;
        }
    }
    #welcome-message{
        padding: 57px;
        gap: 40px;
        .welcome-message-left{
            .pastor-img{
                height:100%;
                img{
                    height: 100%;
                    width: 100%;
                }
            }
        } 
        .welcome-message-right{
            .welcome-title{
                h3 {
                    letter-spacing: 10.25px;
                }
                h2{
                    font-size: 20px;
                }
            } 
            .welcome-text{
                .greeting {
                    font-size: 19px;
                }
                .message{
                    font-size: 13px;
                }
            } 
            .welcome-about{
                gap: 12px;
                .welcome-about-text{
                    font-size: 13px;
                }
            }
        } 
    } 
    #sermons{
        padding: 57px;
        .latest-sermon{
            .latest-sermon-item{
                gap: 0;
                .latest-sermon-thumbnail {
                    height: 254px;
                }
                .sermon-main{
                    gap: 18px;
                    padding: 30px;
                    .sermon-title{
                        font-size: 20px;
                    }
                }
            }
        }
    }  
    #how-we-worship{
        gap: 30px;
        .worship-text{            
            width: 60%;
        }
        
    } 
}

// MOBILE VIEW
@media screen and (max-width: 426px){
    #year-theme {
        height: 40vh;
        .theme-title{
            h3 {
                font-size: 30px;
            }
            .underline {
                width: 200px;
            }
        } 
        .theme {
            width: 80%;
            font-size: 17px;
        }
    }
    #mission-vision-believes{
        .mission-vision {
            flex-direction: column;

        }
    } 
    #welcome-message{
        flex-direction: column;
    }
    #sermons{
        .latest-sermon{
            .latest-sermon-top{
                h3 {
                    font-size: 20px;
                }
                .access-more{
                    font-size: 14px;
                }
            } 
            .latest-sermon-item{
                flex-direction: column;
                audio{
                    width: 100%;
                }
            }
        } 
        .other-sermons{
            .other-sermon-item {
                flex-direction: column;
                height: 230px;
                padding: 10px;
            }
        } 
    } 
    #upcoming-event{
        .event-title, .event-date{
            width: 80%;
            font-size: 30px;
        }
    }
    #how-we-worship{
        display: flex;
        flex-direction: column;
        gap: 20px;
        .worship-text{            
            width: 100%;
        }
        
    }
}