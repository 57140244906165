@import url('https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap');

*{
  margin:0;
  padding:0;
  box-sizing: border-box;
}

:root{
  --main-color: #030166;
  --main-color-hover:#24227a;
}

body{
  font-family: 'Kumbh Sans', sans-serif;
}

a{
  text-decoration: none;
}

ul{
  list-style: none;
}

#main-layout{
  display: flex;
}

#main-page{
  width: 80%;
  background-color: #f3f4f9b2;
  margin-left: 20%;
  min-height: 100vh;
}

.green-badge{
  -webkit-box-align: center;
  align-items: center;
  font-weight: bold;
  font-size: 11px;
  display: inline-block;
  height: auto;
  white-space: nowrap;
  width: auto;
  text-transform: uppercase;
  background: rgba(44, 218, 157, 0.15);
  color: rgb(24, 136, 97);
  position: relative;
  border-radius: 3px;
  overflow: hidden;
  padding: 0px 8px;
  text-overflow: ellipsis;
  line-height: 1rem;
  word-break: break-word;
}

.red-badge{
  -webkit-box-align: center;
  align-items: center;
  font-weight: bold;
  font-size: 11px;
  display: inline-block;
  height: auto;
  white-space: nowrap;
  width: auto;
  text-transform: uppercase;
  background: rgba(216, 30, 91, 0.15);
  color: rgb(126, 18, 53);
  position: relative;
  border-radius: 3px;
  overflow: hidden;
  padding: 0px 8px;
  text-overflow: ellipsis;
  line-height: 1rem;
  word-break: break-word;
}

.default-button{
  padding: 12px 30px;
  /* background-color: var(--main-color); */
  background: linear-gradient(45deg, rgb(0, 28, 121) 0%, rgb(0, 0, 172) 100%);
  color: white;
  display: flex;
  align-items: center;
  gap: 5px;
  width: max-content;
  border-radius: 5px;
}
.default-button:hover{
  cursor: pointer;
  background: var(--main-color-hover);
}


/* TABLET VIEW */
@media screen and (max-width: 769px) {
  #main-page{
    width: 100%;
    margin-left: 0px;
  }
}

/* MOBILE VIEW */
@media screen and (max-width: 426px){
  .default-button{
    padding: 8px 14px;
    font-size: 10px;
  }
}